.fade-in-1s {
  animation: fadein-1 1s ease-in-out;
  animation-iteration-count: 1;
}

.fade-in-2s {
  animation: fadein-2 2s ease-in-out;
  animation-iteration-count: 1;
}

.fade-in-3s {
  animation: fadein-3 3s ease-in-out;
  animation-iteration-count: 1;
}

@keyframes d-arrow {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7px);
  }
  55% {
    transform: translateY(-7px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadein-1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein-2 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein-3 {
  0% {
    opacity: 0;
  }
  66.7% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
