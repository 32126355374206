// Variable overrides first

$primary: #c9ae86;
$text-default-dark: #2b2f32;

$enable-shadows: true;
$prefix: "mo-";
$header-height: 70px;
$product-size: 140px;

@import "src/css/font/bootstrap-icons";
@import "node_modules/bootstrap/scss/bootstrap";
@import "animation";

body {
  color: $text-default-dark;
}

.sm-sidebar {
  width: 220px;
  position: fixed;
  z-index: 90;
  left: 0;
  bottom: 0;
  top: $header-height;
  background: rgba(255 255 255 / 0.75);
  backdrop-filter: blur(3px);
  color: $text-default-dark;
}

.main-header {
  background: $primary;
  box-shadow: 0 1px 2px 2px rgba(0 0 0 / 0.5);

  .sm-menu-button {
    color: white;
    font-size: 1.7rem;
  }

  .nav {
    position: relative;

    li {
      line-height: 2rem;
    }

    .bi {
      font-size: 1.5rem;
    }

    .lang-menu {
      position: absolute;
      right: 0;
    }
  }

  .rainbow {
    background: url("../img/rainbow.png") center no-repeat;
    position: absolute;
    z-index: 200;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100px;
    opacity: 0.5;
  }
}

.jumbo-main {
  margin-top: $header-height;
  background: url("../img/beach.jpg") center / cover no-repeat fixed;
  min-height: 80vh;;

  .jumbo-main-filter {
    margin-top: 40px;
    border-radius: 20px;
    background: rgba(255 255 255 / 0.2);
    backdrop-filter: brightness(1.1) blur(8px);
  }
}

.jumbo-second {
  background: #e0dacc;
}

.products {
  & > * {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .products-img {
    border-radius: 0.5 * $product-size;
    width: $product-size;
    height: $product-size;
  }
}

.form-send {
  width: 100%;
  position: relative;

  .form-textarea,
  .form-input {
    width: 100%;
    outline: none;
    font-size: 1.5rem;
    padding: 0.5rem;
    border: none;
    border-bottom: 2px solid $primary;
    margin-bottom: 1rem;
  }
}

.price {
	font-size: 3rem;
	font-weight: 200;
	color: #333;
	
	&.price-green {
		color: #090;
	}
	
	strike {
		color: #900;
	}
}

.price-icon {
	font-size: 6rem;
}

.preloader {
	position: absolute;
	inset: 0;
	z-index: 10;
	background: rgba(0 0 0 / 0.2);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column nowrap;
	
	&.preloader-hidden {
		display: none;
	}
}

.spinner {
  width: 100px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid #0000;
  border-right-color: rgba(255 255 255 / 0.9);
  position: relative;
  animation: s4 1s infinite linear;
}
.spinner:before,
.spinner:after {
  content: "";
  position: absolute;
  inset: -8px;
  border-radius: 50%;
  border: inherit;
  animation: inherit;
  animation-duration: 2s;
}
.spinner:after {
  animation-duration: 4s;
}

@keyframes s4 {
  100% {transform: rotate(1turn)}
}

.message {
	text-align: center;
	font-size: 2em;
	position: absolute;
	background: #fff;
	inset: 0;
	z-index: 20;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column nowrap;
	
	&.message-hidden {
		display: none;
	}
	
	&.message-error {
		color: #900;
	}
}